import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  CloudServerOutlined,
  DesktopOutlined,
  DollarOutlined,
  GiftOutlined,
  GoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  PictureOutlined,
  SelectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BarcodeOutlined,
  BuildOutlined,
  SlidersOutlined,
  OneToOneOutlined,
  EuroCircleOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  PhoneOutlined,
  MessageOutlined,
  ScheduleOutlined,
  NodeIndexOutlined,
  LineChartOutlined,
  KeyOutlined,
  MedicineBoxOutlined,
  IssuesCloseOutlined,
  CheckCircleOutlined,
  AccountBookOutlined,
  FlagOutlined,
  LaptopOutlined,
  Loading3QuartersOutlined,
  BankOutlined,
  PercentageOutlined,
  ExperimentOutlined,
  YuqueOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoCashOutline } from "react-icons/io5";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
import {
  MdBikeScooter,
  MdDisabledVisible,
  MdPointOfSale,
  MdStar,
  MdStarBorder,
} from "react-icons/md";
import moment from "moment";
import { siteInfo } from "../utils/SiteInfo";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const roles = useSelector((state) => state.auth.profile?.roles);
  const activated = useSelector((state) => state.auth.activated);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const menuStyle = {
    fontSize: "16px",
    width: "18px",
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[path.toString()]}
      defaultOpenKeys={["accounts"]}
    >
      <Menu.Item key={"/"} icon={<HomeOutlined style={menuStyle} />}>
        <Link to={"/"}>Dashboard</Link>
      </Menu.Item>

      {isPermitted(roles, ["super_admin", "admin"]) && activated && (
        <>
          <Menu.Item
            key={"/customers"}
            icon={<TeamOutlined style={menuStyle} />}
          >
            <Link to={"/customers"}>Customers</Link>
          </Menu.Item>

          <Menu.Item key={"/orders"} icon={<GoldOutlined style={menuStyle} />}>
            <Link to={"/orders"}>Orders</Link>
          </Menu.Item>
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/monthly-report"}
              icon={<CalendarOutlined style={menuStyle} />}
            >
              <Link to={"/monthly-report"}>Monthly Report</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/customer-report"}
              icon={<AccountBookOutlined style={menuStyle} />}
            >
              <Link to={"/customer-report"}>Active Customer & Slab</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/inactive-customer-report"}
              icon={<MdDisabledVisible style={menuStyle} />}
            >
              <Link to={"/inactive-customer-report"}>Inactive Customer</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/point-customer"}
              icon={<MdStarBorder style={menuStyle} />}
            >
              <Link to={"/point-customer"}>Point Customer</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/profit-report"}
              icon={<DollarOutlined style={menuStyle} />}
            >
              <Link to={"/profit-report"}>Profit Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/bank-report"}
              icon={<BankOutlined style={menuStyle} />}
            >
              <Link to={"/bank-report"}>Bank Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/investment-report"}
              icon={<PercentageOutlined style={menuStyle} />}
            >
              <Link to={"/investment-report"}>Investment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/rmb-transaction-report"}
              icon={<PayCircleOutlined style={menuStyle} />}
            >
              <Link to={"/rmb-transaction-report"}>RMB Transaction Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/delivery-payment-report"}
              icon={<CarOutlined style={menuStyle} />}
            >
              <Link to={"/delivery-payment-report"}>
                Delivery Payment Report
              </Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/shipment-report"}
              icon={<FlagOutlined style={menuStyle} />}
            >
              <Link to={"/shipment-report"}>Shipment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/delivery-due-report"}
              icon={<MdBikeScooter style={menuStyle} />}
            >
              <Link to={"/delivery-due-report"}>Delivery Due Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/warehouse-courier-report"}
              icon={<GoldOutlined style={menuStyle} />}
            >
              <Link to={"/warehouse-courier-report"}>
                Warehouse Courier Report
              </Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/employee-purchasing-report"}
              icon={<PictureOutlined style={menuStyle} />}
            >
              <Link to={"/employee-purchasing-report"}>Purchasing Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/employee-purchase-report"}
              icon={<DesktopOutlined style={menuStyle} />}
            >
              <Link to={"/employee-purchase-report"}>Purchased Report</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/purchase-report"}
              icon={<KeyOutlined style={menuStyle} />}
            >
              <Link to={"/purchase-report"}>Purchase Cost Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/refund-report"}
              icon={<LaptopOutlined style={menuStyle} />}
            >
              <Link to={"/refund-report"}>Refund Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant"]) && (
            <Menu.Item
              key={"/payments"}
              icon={<IoCashOutline style={menuStyle} />}
            >
              <Link to={"/payments"}>Payments</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/payment-report"}
              icon={<ScheduleOutlined style={menuStyle} />}
            >
              <Link to={"/payment-report"}>Payment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/courier-payment-report"}
              icon={<SlidersOutlined style={menuStyle} />}
            >
              <Link to={"/courier-payment-report"}>Courier Payment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/steadfast-report"}
              icon={<MedicineBoxOutlined style={menuStyle} />}
            >
              <Link to={"/steadfast-report"}>SteadFast Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/skyexpress-report"}
              icon={<MdBikeScooter style={menuStyle} />}
            >
              <Link to={"/skyexpress-report"}>SkyExpress Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/gateway-report"}
              icon={<NodeIndexOutlined style={menuStyle} />}
            >
              <Link to={"/gateway-report"}>Gateway Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/cash-report"}
              icon={<LineChartOutlined style={menuStyle} />}
            >
              <Link to={"/cash-report"}>Cash Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/call-report"}
              icon={<PhoneOutlined style={menuStyle} />}
            >
              <Link to={"/call-report"}>Call Report</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/sms-report"}
              icon={<MessageOutlined style={menuStyle} />}
            >
              <Link to={"/sms-report"}>Sms Report</Link>
            </Menu.Item>
          )}
        </>
      )}

      <Menu.Item
        key="/SettingOutlined "
        icon={<UserOutlined style={{ fontSize: "14px", width: "18px" }} />}
      >
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="/logout"
        onClick={() => {
          dispatch(logoutAdmin(() => {}));
          openNotificationWithIcon(
            "success",
            "Success",
            "Logged out successfully"
          );
        }}
        icon={<LogoutOutlined style={{ fontSize: "16px", width: "18px" }} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
