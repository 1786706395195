import "./App.css";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { isPermitted } from "./utils/PermissionManager";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";

const RmbTransactionReport = lazy(() => import("./pages/RmbTransactionReport"));
const CourierPaymentReport = lazy(() => import("./pages/CourierPaymentReport"));
const SkyExpressReport = lazy(() => import("./pages/SkyExpressReport"));

const InactiveCustomerReport = lazy(() =>
  import("./pages/InactiveCustomerReport")
);
const PointCustomer = lazy(() => import("./pages/PointCustomers"));
const Customers = lazy(() => import("./pages/Customers"));
const Orders = lazy(() => import("./pages/Orders"));
const Payments = lazy(() => import("./pages/Payments"));

const CustomerReport = lazy(() => import("./pages/CustomerReport"));

const WarehouseCourierReport = lazy(() =>
  import("./pages/WarehouseCourierReport")
);
const DeliveryDueReport = lazy(() => import("./pages/DeliveryDueReport"));

const ShipmentInvoiceReport = lazy(() =>
  import("./pages/ShipmentInvoiceReport")
);

const EmployeePurchasingReport = lazy(() =>
  import("./pages/EmployeePurchasingReport")
);

const BankReport = lazy(() => import("./pages/BankReport"));
const InvestmentReport = lazy(() => import("./pages/InvestmentReport"));
const EmployeePurchaseReport = lazy(() =>
  import("./pages/EmployeePurchaseReport")
);

const RefundReport = lazy(() => import("./pages/RefundReport"));

const DeliveryPaymentReport = lazy(() =>
  import("./pages/DeliveryPaymentReport")
);

const SteadFastReport = lazy(() => import("./pages/SteadFastReport"));

const PurchaseReport = lazy(() => import("./pages/PurchaseReport"));
const CallReport = lazy(() => import("./pages/CallReport"));
const GateWayReport = lazy(() => import("./pages/GatewayReport"));
const PaymentReport = lazy(() => import("./pages/PaymentReport"));

const SmsReport = lazy(() => import("./pages/SmsReport"));
const CashReport = lazy(() => import("./pages/CashReport"));
const ProfitReport = lazy(() => import("./pages/ProfitReport"));
const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage.skyadminrep) {
  setAuthToken(localStorage.skyadminrep);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  const activated = store.getState().auth?.activated;

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#D1412A",
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {roles?.length > 0 && <Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Dashboard />}
                      />
                    }
                  />

                  {activated && (
                    <>
                      <Route
                        exact
                        path="/customers"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Customers />}
                          />
                        }
                      />

                      <Route
                        exact
                        path="/orders"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Orders />}
                          />
                        }
                      />
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/shipment-report/:id?"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<ShipmentInvoiceReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/delivery-due-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<DeliveryDueReport />}
                            />
                          }
                        />
                      )}

                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/warehouse-courier-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<WarehouseCourierReport />}
                            />
                          }
                        />
                      )}

                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/monthly-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<MonthlyReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/customer-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<CustomerReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/inactive-customer-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<InactiveCustomerReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/point-customer"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<PointCustomer />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/bank-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<BankReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/investment-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<InvestmentReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/employee-purchase-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<EmployeePurchaseReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/employee-purchasing-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<EmployeePurchasingReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/rmb-transaction-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<RmbTransactionReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/steadfast-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<SteadFastReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/skyexpress-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<SkyExpressReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, [
                        "super_admin",
                        "admin",
                        "accountant",
                        "delivery",
                      ]) && (
                        <Route
                          exact
                          path="/delivery-payment-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<DeliveryPaymentReport />}
                            />
                          }
                        />
                      )}

                      {isPermitted(roles, ["super_admin", "admin"]) && (
                        <Route
                          exact
                          path="/refund-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<RefundReport />}
                            />
                          }
                        />
                      )}

                      {isPermitted(roles, ["super_admin"]) && (
                        <Route
                          exact
                          path="/profit-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<ProfitReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/gateway-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<GateWayReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/purchase-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<PurchaseReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin", "accountant"]) && (
                        <Route
                          exact
                          path="/payments"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<Payments />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/payment-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<PaymentReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/courier-payment-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<CourierPaymentReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/sms-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<SmsReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/cash-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<CashReport />}
                            />
                          }
                        />
                      )}
                      {isPermitted(roles, ["admin"]) && (
                        <Route
                          exact
                          path="/call-report"
                          element={
                            <AdminRoute
                              state={{ show, trigger }}
                              component={<CallReport />}
                            />
                          }
                        />
                      )}
                    </>
                  )}

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
